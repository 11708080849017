import {
  Box,
  Text,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  Layout,
  FeatureList,
  Button,
  Tooltip,
  IconButton,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Bb from "./bb.png";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/back-button/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=c4f3850c-ea6b-4650-bb5b-c85e832e34ea&redirectUrl=https://certifiedcode.wixsite.com/back-button/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Let site visitor go back to previous page"
                content="The back button that you always wanted for your site. Let your site visitors go back to the previous page with a single click."
                actions={
                  <Box direction="horizontal" gap="SP1">
                    <Button
                      as="a"
                      href="https://www.youtube.com/watch?v=XMAMbu2tOUU"
                      target="_blank"
                      prefixIcon={<Icons.PlayFilled />}
                    >
                      Setup Tutorial
                    </Button>
                    {/* <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => {
                    window.open(`https://www.wix.com/apps/upgrade/${"c4f3850c-ea6b-4650-bb5b-c85e832e34ea"}?appInstanceId=${(instanceData as any)['instance']['instanceId']}`)
                  }}
                >Upgrade App</Button> */}
                    <Button
                      prefixIcon={<Icons.Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/c4f3850c-ea6b-4650-bb5b-c85e832e34ea"
                      target="_blank"
                    >
                      Write a review
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={Bb} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
